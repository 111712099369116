<template>
  <div
    class="ml-select-time-item-manual"
    :class="{ active: active }"
    ref="block"
    @click="selectManual"
  >
    <input
      type="text"
      class="ml-select-time-item-manual_input"
      placeholder="ЧЧ:ММ"
      v-mask="'##:##'"
      v-model="time"
      @input="debouncedChange"
    />
    <span class="ml-select-time-item-manual_text">Свое время</span>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { debounce } from 'lodash'

export default {
  name: 'MlInputTime',
  directives: { mask },
  props: {
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: Object
    }
  },
  data: () => ({
    time: null
  }),
  created() {
    this.debouncedChange = debounce(this.change, 250)
  },
  mounted() {
    this.time = this.value
  },
  methods: {
    setValue(value) {
      this.time = value
    },
    change() {
      const times = this.time.split(':')
      let isChanged = false
      let n1 = times[0]
      let n2 = times[1]
      if (n1 && n1.length > 1 && parseInt(n1) > 23) {
        n1 = 23
        isChanged = true
      }
      if (n2 && n2.length > 1 && parseInt(n2) > 59) {
        n2 = 59
        isChanged = true
      }
      if (isChanged) {
        if (!n2) {
          this.time = `${n1.toString().padStart(2, '0')}:`
        } else {
          this.time = `${n1
            .toString()
            .padStart(2, '0')}:${n2.toString().padStart(2, '0')}`
        }
      }
      this.$emit('select-manual', this.time)
    },
    selectManual() {
      this.$emit('select-manual', this.time)
    }
  }
}
</script>
