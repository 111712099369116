<template>
  <div class="ml-panel-basket-page h100">
    <div
      class="d-flex flex-column align-center justify-center vh100"
      v-if="!basket || !basket.length"
    >
      <img src="~@/assets/img/empty-cart.png" alt="" class="empty-cart-img" />
      <section class="empty-text">
        <div>Ваша корзина пока пуста.</div>
        <div>Добавьте первый сертификат</div>
      </section>
      <a href="#" @click.prevent="addCertificate" class="ml-black-btn">
        <v-icon>mdi-plus</v-icon>
        Добавить сертификат
      </a>
    </div>
    <div class="h100 d-flex flex-column" v-else>
      <div class="pb flex-grow-1 ">
        <div class="section mb-4">Корзина</div>
        <template v-for="(item, itemIndex) in basket">
          <div class="ml-silver-block mb-3" :key="itemIndex">
            <the-basket-item
              :ref="'the-basket-item-' + itemIndex"
              :index="itemIndex"
              :item="item"
            />
          </div>
        </template>

        <div class="d-flex justify-content-between my-6">
          <div class="text1">Общая стоимость</div>
          <div class="text1">{{ totalPrice }}₽</div>
        </div>
        <div class="mb-8">
          <a
            href="#"
            @click.prevent="addCertificate"
            class="ml-silver-btn ml-bg-silver1"
          >
            <v-icon>mdi-plus</v-icon>
            Добавить еще сертификат
          </a>
        </div>
      </div>
      <!-- <div
        class="pb flex-grow-1"
        style="border-top: 1px solid #E6E6E6"
        v-if="!isVerified"
      >
        <div class="section">Контакты для отправки</div>
        <div class="text2 mb-3">
          Укажите ваши контакты, чтобы мы могли отправить на них сообщение со
          ссылкой на сертификаты(ы). После получения сообщения вы сможете
          переслать его кому угодно.
        </div>
      </div> -->
      <div class="controlls" ref="controlls">
        <a
          v-if="!isVerified"
          href="#"
          @click.prevent="goToSending"
          class="ml-black-btn"
        >
          Указать контакты для отправки
        </a>
        <a v-else href="#" @click.prevent="goToConfirming" class="ml-black-btn">
          Перейти к оформлению
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import MixinChangePanelPage from '@/helpers/mixins/panel/changePage'
import MixinObserveElement from '@/helpers/mixins/observeElement'
import MixinCommon from '@/helpers/mixins/common'
import { mapGetters, mapMutations, mapState } from 'vuex'
import TheBasketItem from '@/components/Panel/TheBasketItem'
import basketTypes from '@/store/basket/types'

export default {
  mixins: [MixinChangePanelPage, MixinObserveElement, MixinCommon],
  components: {
    TheBasketItem
  },
  computed: {
    ...mapState({
      basket: state => state.basket.basket
    }),
    ...mapGetters('basket', ['allPositions']),
    ...mapGetters(['verificationCode/isVerified']),
    isVerified() {
      return this['verificationCode/isVerified']
    },
    observedElement() {
      return this.$refs.controlls
    },
    totalPrice() {
      const trunc = Math.trunc(this.allPositions?.price)
      const diff = trunc - this.allPositions?.price
      return diff === 0
        ? this.allPositions?.price
        : this.allPositions?.price.toFixed(2)
    }
  },
  watch: {
    basket() {
      Object.keys(this.$refs).forEach(key => {
        const el = this.$refs[key][0]
        if (el) el.$forceUpdate()
      })
    }
  },
  mounted() {
    this.showNavs(true)
    this.handleScroll()
  },
  methods: {
    ...mapMutations('basket', [basketTypes.SET_CURRENT_CERTIFICATE]),
    goToConfirming() {
      this.$router.push('/confirming')
    },
    goToSending() {
      this.$router.push('/sending')
    },
    addCertificate() {
      this[basketTypes.SET_CURRENT_CERTIFICATE](null)
      this.$store.commit('basket/SET_PREVIEW', null)
      this.showNavsAndRedirect(false, { name: 'Order' })
      // this.$router.push('/')
    }
  }
}
</script>
