<template>
  <div class="ml-calendar">
    <!-- <a href="#" @click.prevent="next">next</a> -->
    <div class="ml-calendar-header">
      <div class="ml-calendar-header_month">
        {{ currentMonthYear }}
      </div>
      <div class="ml-calendar-header_nav">
        <img @click="prev" src="@/assets/img/calendar/arrow-left.svg" alt="" />
        <img
          @click="today"
          class="mx-4"
          src="@/assets/img/calendar/dot.svg"
          alt=""
        />
        <img @click="next" src="@/assets/img/calendar/arrow-right.svg" alt="" />
      </div>
    </div>
    <v-date-picker
      full-width
      ref="picker"
      :weekday-format="days"
      :header-date-format="yearFormat"
      :first-day-of-week="1"
      :min="minDate"
      no-title
      color="green lighten-1"
      :value="value"
      locale="ru-ru"
      @change="changePicker"
    ></v-date-picker>
  </div>
</template>

<script>
import { format, parseISO, addMonths } from 'date-fns'
import Constants from '@/helpers/constants'
// const dayOfWeek = ['Вс', 'Пн', 'Вт', ' Ср', 'Чт', 'Пт', 'Сб']
const monthes = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
]

export default {
  name: 'MlCalendar',
  model: {
    prop: 'value'
  },
  props: {
    value: {
      type: String
    }
  },
  data: () => ({
    currentMonthYear: null,
    todayMonthYear: null
  }),
  mounted() {
    this.setInitialize()
  },
  computed: {
    minDate() {
      return format(new Date(), 'yyyy-MM-dd[T]HH:mm:ss')
    },
    picker() {
      return this.$refs?.picker
    }
    // currentMonth() {
    //   const now = this.$refs?.picker?.tableDate
    //   console.log(now)
    //   console.log(now)
    //   return 123
    //   // const year = now.getFullYear(now)
    //   // const month = now.getMonth(now)
    //   // return `${monthes[month]} ${year} `
    // }
  },
  methods: {
    calcCurrentMonthYear() {
      const now = new Date(this.$refs?.picker?.tableDate)
      const year = now.getFullYear(now)
      const month = now.getMonth(now)
      this.currentMonthYear = `${monthes[month]} ${year} `
    },
    setTodayMonthYear() {
      const current = parseISO(this.picker.tableDate)
      this.todayMonthYear = format(current, 'yyyy-MM')
    },
    setInitialize() {
      this.calcCurrentMonthYear()
      this.setTodayMonthYear()
    },
    changePicker(item) {
      this.$emit('select-date', item)
    },
    next() {
      const current = parseISO(this.picker.tableDate)
      const newMonth = addMonths(current, 1)
      this.$refs.picker.tableDate = format(newMonth, 'yyyy-MM')
      this.calcCurrentMonthYear()
    },
    prev() {
      const today = parseISO(this.todayMonthYear)
      const current = parseISO(this.picker.tableDate)
      const newMonth = addMonths(current, -1)
      if (newMonth >= today) {
        this.$refs.picker.tableDate = format(newMonth, 'yyyy-MM')
        this.calcCurrentMonthYear()
      }
    },
    today() {
      this.$refs.picker.tableDate = this.todayMonthYear
      this.calcCurrentMonthYear()
    },
    yearFormat(item) {
      const now = new Date(item)
      const year = now.getFullYear(item)
      const month = now.getMonth(item)
      return `${monthes[month]} ${year} `
    },
    days(item) {
      return Constants?.DAY_OF_WEEK[new Date(item).getDay(item)]?.short
    }
  }
}
</script>
